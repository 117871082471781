import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout>
    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <h1>PUSLAPIS NERASTAS</h1>
          <p>Bandote pasiekti neegzistuojantį puslapį</p>
          <Link to="/" className="link primary">
            <button type="button" className="button">Grįžti į pradžią</button>
          </Link>
        </div>
      </section>
    </div>
  </Layout>
);

export default NotFoundPage;
